import React, { useState } from 'react';
import {
	Box,
	Card,
	Divider,
	IconButton,
	Link,
	Typography,
	Zoom,
} from '@mui/material';
import {
	Clear,
	GavelRounded,
	Group,
	OpenInNewRounded,
	ShareRounded,
	Star,
	SwapHorizRounded,
	VerifiedUser,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../../helpers';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import PlatformIcon from '../../../../components/PlatformIcon';

import TagSelector from '../../../../components/TagSelector';
import CardDenounceConfirmationDialog from '../CardActionsConfirmationDialog';
import AuthorBindModal from '../../../../components/AuthorBindModal';
import AuthorDetailsModal from '../../AuthorModal';

import LoadingDots from '../../../../components/LoadingDots';
import { TagObjectType } from '../../../../interfaces';
import { useSnackbar } from 'notistack';
import { submitAuthorsTags } from '../../../../services/authors';

interface Props {
	author: any;
	handleSetTags: (cardTags) => void;
	handleFlip: () => void;
	className: any;
	boundEntity: any;
	setBoundEntity: (entity: any) => void;
	loading: boolean;
}

const CardBack: React.FC<Props> = ({
	author,
	handleFlip,
	setBoundEntity,
	className,
	boundEntity,
	loading,
}) => {
	const { selectedTheme, tagsActiveClient, selectedClient } = usePO();
	const { t } = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const [cardTags, setCardTags] = useState<any[]>([]);
	const [lastTags, setLastTags] = useState<TagObjectType[]>([]);
	const [loadingTags, setLoadingTags] = useState(false);

	const [openAuthorDetailsModal, setOpenAuthorDetailsModal] = useState(false);
	const [authorDataLength] = useState(
		() => Object.keys(author.authorData).length
	);

	const handleSaveTags = async () => {
		setLoadingTags(true);
		const tagsPayload = {
			offerIDs: [author.authorID],
			tagIDs: cardTags.map((el: TagObjectType) => el.tagID),
		};
		const response: any = await submitAuthorsTags(
			tagsPayload,
			selectedClient.id
		);
		if (response.success) {
			setLastTags(response.successTags);
			enqueueSnackbar(t(response.message), {
				variant: 'success',
			});
		}
		if (response.error) {
			setCardTags(lastTags);
		}
		setLoadingTags(false);
	};

	const renderDescription = () => {
		if (author.authorData.description?.value) {
			return (
				<Column>
					<Typography variant="subtitle1">{t('Autores.Descrição')}:</Typography>
					<Typography
						variant="caption"
						style={{
							height: 207,
							maxWidth: 210,
							marginTop: 5,
							overflowY: 'auto',
							overflowX: 'hidden',
							whiteSpace: 'pre-line',
							alignSelf: 'flex-start',
						}}
					>
						{author.authorData.description.value}
					</Typography>
				</Column>
			);
		}

		return null;
	};

	const renderRatingInfo = () => {
		if (author.authorData.rating?.value) {
			return (
				<Line style={{ justifyContent: 'center' }}>
					<Star data-testid="star-icon" color="primary" fontSize="inherit" />
					<Typography
						variant="caption"
						style={{
							maxWidth: 210,
							overflowY: 'auto',
							overflowX: 'hidden',
							whiteSpace: 'pre-line',
							marginLeft: 4,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					>
						{author.authorData.rating?.value}
					</Typography>
				</Line>
			);
		}

		return null;
	};

	const renderVerifiedInfo = () => {
		if (!author.authorData.verified) {
			return null;
		}
		if (author.authorData.verified.value) {
			return (
				<Line style={{ paddingTop: 5, justifyContent: 'center' }}>
					<Typography
						variant="caption"
						style={{
							maxWidth: 210,
							overflowY: 'auto',
							overflowX: 'hidden',
							whiteSpace: 'pre-line',
							marginRight: 4,
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Autores.Verificado')}:
					</Typography>
					<VerifiedUser color="action" fontSize="inherit" />
				</Line>
			);
		}

		return (
			<Line style={{ paddingTop: 5, justifyContent: 'center' }}>
				<Typography
					variant="caption"
					style={{
						maxWidth: 210,
						overflowY: 'auto',
						overflowX: 'hidden',
						whiteSpace: 'pre-line',
						marginRight: 4,
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Autores.Verificado')}:
				</Typography>
				<Clear
					data-testid="notVerifiedIcon"
					htmlColor="#C91212"
					fontSize="inherit"
				/>
			</Line>
		);
	};

	const renderFollowersInfo = () => {
		if (author.authorData.followers) {
			return (
				<Line style={{ paddingTop: 5, justifyContent: 'center', gap: 5 }}>
					<Group
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
						fontSize="inherit"
					/>
					<Typography
						variant="caption"
						style={{
							paddingTop: 4,
							maxWidth: 210,
							overflowY: 'auto',
							overflowX: 'hidden',
							whiteSpace: 'pre-line',
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{author.authorData.followers.value}{' '}
						{author.authorData.followers === 1
							? t('Autores.Seguidor')
							: t('Autores.Seguidores')}
					</Typography>
				</Line>
			);
		}

		return null;
	};

	const handleCopyLinkToURL = () => {
		const { protocol, host } = window.location;
		const url = `${protocol}//${host}/authorID/${author.authorID}`;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				enqueueSnackbar(
					t('Classificações.Link copiado para a área de transferência'),
					{ variant: 'success' }
				);
			})
			.catch((err) => {
				console.error('Error copying to clipboard', err);
			});
	};

	const renderCardHeader = () => (
		<Line
			style={{
				alignItems: 'center',
				justifyContent: 'space-between',
				position: 'absolute',
				top: 0,
				padding: '5px 2px 0 5px',
			}}
		>
			<IconButton
				data-testid="flip-button-back"
				size="small"
				onClick={handleFlip}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<SwapHorizRounded
					style={{
						zIndex: 10,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
			<OfferToolTip
				title={
					<Typography variant="subtitle2" align="center">{`${t(
						'Autores.Abrir perfil do autor na plataforma de origem'
					)} (${author.platformINcountryID?.replace('|', ' - ')})`}</Typography>
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Link href={author.permalink} target="blank">
					<PlatformIcon
						data={author.platformINcountryID}
						dataType="platform"
						size={20}
						cursor="pointer"
					/>
				</Link>
			</OfferToolTip>
			<AuthorBindModal
				authorData={author}
				boundTo={boundEntity}
				display="menu"
				setBoundTo={setBoundEntity}
			/>
		</Line>
	);

	const renderCardInfo = () => (
		<Column
			style={{
				padding: '35px 10px 10px 10px',
				height: '100%',
				justifyContent:
					authorDataLength > 2 && author.authorData.description?.value
						? 'space-evenly'
						: 'flex-start',
			}}
		>
			{authorDataLength > 0 && (
				<>
					{renderDescription()}
					<Column style={{ justifyContent: 'flex-start' }}>
						<OfferToolTip
							title={`${t('Autores.Abrir página do autor na plataforma')}`}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<Box
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								width="200px"
							>
								<Link
									underline="none"
									href={author.permalink}
									target="blank"
									color="inherit"
								>
									<Typography
										align="center"
										noWrap
										style={{
											fontWeight: 'bold',
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorHigh,
											cursor: 'pointer',
										}}
									>
										{author.authorName || author?.authorData.nickname?.value}
									</Typography>
								</Link>
							</Box>
						</OfferToolTip>
						{renderRatingInfo()}
						{renderVerifiedInfo()}
						{renderFollowersInfo()}
					</Column>
				</>
			)}
		</Column>
	);

	const renderTagSelector = () => (
		<Column style={{ alignItems: 'start', padding: '0 20px', rowGap: 5 }}>
			<TagSelector
				setDataArray={setCardTags}
				dataArray={cardTags}
				handleSaveTags={() => handleSaveTags()}
				suggestions={tagsActiveClient}
				loading={loadingTags}
				autoSave
				rows={1}
				size="small"
				tagSize="small"
				limitTags={1}
				width={'100%'}
			/>
		</Column>
	);

	const renderCardFooter = () => (
		<>
			<Divider
				sx={{
					background: selectedTheme.id === 'dark' && selectedTheme.footerLine,
				}}
				style={{ margin: '10px 15px 3px' }}
			/>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: '0 10px 0 8px',
				}}
			>
				<Line style={{ justifyContent: 'flex-start' }}>
					<OfferToolTip
						title={`${t('Autores.Compartilhar')}`}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						arrow
						TransitionComponent={Zoom}
					>
						<IconButton
							onClick={handleCopyLinkToURL}
							sx={{
								padding: '6px',
								marginLeft: '-5px',
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<ShareRounded
								style={{
									fontSize: 20,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.primaryDark,
								}}
							/>
						</IconButton>
					</OfferToolTip>
				</Line>
				<Line style={{ justifyContent: 'flex-end' }}>
					<CardDenounceConfirmationDialog author={author} />
				</Line>
			</Line>
		</>
	);

	const renderBoundEntity = () =>
		boundEntity ? (
			<>
				<OfferToolTip
					title={`${t('Autores.Este autor é vinculado a uma entidade')}`}
					aria-label="bound-to-Entity"
					arrow
					TransitionComponent={Zoom}
				>
					<GavelRounded
						style={{
							fontSize: 15,
							margin: '-2px 10px 0 0',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</OfferToolTip>
				<OfferToolTip
					title={boundEntity.name}
					aria-label="bound-to"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						sx={{
							padding: '6px',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<OpenInNewRounded
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					</IconButton>
				</OfferToolTip>
				<AuthorBindModal
					authorData={author}
					boundTo={boundEntity}
					setBoundTo={setBoundEntity}
					display="card"
				/>
			</>
		) : (
			<Typography
				variant="subtitle2"
				align="center"
				noWrap
				style={{
					width: '100%',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				Não vinculado a uma entidade
			</Typography>
		);

	const renderEntityInfo = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				padding: '7px 15px 7px 20px',
			}}
		>
			{loading ? (
				<Line style={{ justifyContent: 'center', marginTop: -1 }}>
					<LoadingDots width={40} />
				</Line>
			) : (
				renderBoundEntity()
			)}
		</Line>
	);

	const renderCardRightLine = () => (
		<Column
			style={{
				width: 3,
				height: 330,
				background: author.offerRisk ? selectedTheme.error : 'transparent',
				opacity: 0.7,
			}}
		/>
	);

	const handleAuthorModalClose = () => {
		setOpenAuthorDetailsModal(false);
	};

	return (
		<>
			<Card
				className={className}
				style={{
					width: 250,
					background: selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					justifyContent: 'space-between',
				}}
			>
				<Line style={{ height: 330 }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: '100%',
							width: 247,
							paddingTop: 5,
						}}
					>
						{renderCardHeader()}
						<div
							data-testid="Clickable Card"
							onClick={() => setOpenAuthorDetailsModal(true)}
							style={{
								marginTop: cardTags.length > 0 ? 8 : 16,
								cursor: 'pointer',
							}}
						>
							{renderCardInfo()}
						</div>
						<div>
							{renderEntityInfo()}
							{renderTagSelector()}
							<Divider
								sx={{
									background:
										selectedTheme.id === 'dark' && selectedTheme.footerLine,
								}}
								style={{ margin: '0px 15px' }}
							/>
							{renderCardFooter()}
						</div>
					</div>
					{renderCardRightLine()}
				</Line>
			</Card>
			<AuthorDetailsModal
				open={openAuthorDetailsModal}
				handleClose={handleAuthorModalClose}
				authorData={author}
			/>
		</>
	);
};
export default CardBack;
