import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { DataSearch } from '@appbaseio/reactivesearch';

import { useTranslation } from 'react-i18next';
import { api, isAxiosError } from '../../../utils/api';
import { usePO } from '../../../utils/POContext';
import { Line } from '../../../styles';
import { ErrorResponse, userType } from '../../../interfaces';
import { makeStyles } from '@mui/styles';

interface Props {
	keyLogic: any;
	selectedUser: any;
	setSelectedUser: any;
	setDenouncements: any;
	denouncements: any;
}

const ViewDenouncementItems: React.FC<Props> = ({
	selectedUser,
	setSelectedUser,
	setDenouncements,
	denouncements,
}) => {
	const { t } = useTranslation();
	const { updateCursorMode, selectedTheme, selectedClient } = usePO();

	const [allUsers, setAllUsers] = useState<userType[]>([]);

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			updateCursorMode('wait');
			const usersData = await api.get(
				`/userINclient/client/${selectedClient?.id}`
			);
			setAllUsers(usersData.data);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					console.log(errorResponse.data.detail);
				}
			}
		} finally {
			updateCursorMode('default');
		}
	};

	const extractEmailFromUserID = (userID) => {
		const parts = userID?.split('|');
		return parts?.length > 2 ? parts[2] : '';
	};

	const reactiveSearchHiddenComponents = () => (
		<DataSearch
			componentId="denouncementsUsers"
			filterLabel={`${t('FilterBar.Denunciado por')} ${extractEmailFromUserID(
				denouncements
			)}`}
			value={denouncements}
			dataField="denouncementUserID"
			showIcon={false}
			autosuggest={false}
			highlight={false}
			fuzziness="AUTO"
			queryFormat="and"
			debounce={1}
			style={{ display: 'none' }}
		/>
	);

	const handleChangeUser = (user) => {
		setSelectedUser(user);
		if (user !== null) {
			hadleSetViewDenouncementItems(user);
		} else {
			setDenouncements('');
		}
	};

	const hadleSetViewDenouncementItems = async (user: userType) => {
		setDenouncements(user.userID);
	};

	return (
		<Line
			style={{
				margin: '-5px 0 -5px',
			}}
		>
			{reactiveSearchHiddenComponents()}
			<Autocomplete
				id="denounceUser"
				data-testid="denounceUserID"
				size={'small'}
				options={allUsers as userType[]}
				onChange={(_, newValue) => {
					handleChangeUser(newValue);
				}}
				value={selectedUser}
				autoHighlight
				autoComplete
				autoSelect
				selectOnFocus
				classes={{
					option: classes.autoCompleteStyle,
					paper: classes.autoCompleteStyle,
				}}
				sx={{ width: '100%' }}
				getOptionLabel={(option) => option.userID?.split('|')[2] ?? ''}
				renderOption={(props, option) => (
					<li {...props}>{option.userID?.split('|')[2]}</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						name="denouncementsUsers"
						placeholder={t('FilterBar.Busca por denunciante')}
						variant="outlined"
						inputProps={{
							...params.inputProps,
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						sx={{
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							width: '100%',
						}}
					/>
				)}
			/>
		</Line>
	);
};
export default ViewDenouncementItems;
